type States = 'active' | 'hover' | 'disabled' | 'default' | 'error';
export const INPUT_COMBO_STYLE_BY_TYPE: Record<States, { dark: string; light: string }> = {
  default: {
    dark: 'bg-dark-400 placeholder-text-dark-100 text-text-dark-500 border border-dark-400',
    light: 'bg-light-300 placeholder-text-light-100 text-text-light-500 border border-dark-300',
  },
  active: {
    dark: 'border !border-primary-300',
    light: 'border !border-primary-300',
  },
  hover: {
    dark: 'hover:bg-dark-250 hover:border-dark-250',
    light: 'hover:bg-light-450 hover:border-dark-450',
  },
  disabled: {
    dark: 'bg-dark-400 placeholder-text-dark-100 text-text-dark-100 border border-dark-400',
    light: 'bg-light-300 placeholder-text-light-100 text-text-light-100 border border-dark-400',
  },
  error: {
    dark: 'bg-dark-400 placeholder-text-dark-100 text-text-dark-500 border !border-red-300',
    light: 'bg-light-300 placeholder-text-light-100 text-text-light-500 border !border-red-300',
  },
};

export const INPUT_COMBO_ACTION_STYLE_BY_TYPE: Record<
  'noValue' | 'hasValue',
  Record<States, { dark: string; light: string }>
> = {
  noValue: {
    default: {
      dark: 'border border-dark-300 text-text-dark-500',
      light: 'border border-light-300 text-text-light-500',
    },
    active: {
      dark: 'border border-dark-300 text-text-dark-500',
      light: 'border border-light-300 text-text-light-500',
    },
    hover: {
      dark: 'group-hover:border-dark-300 text-text-dark-500',
      light: 'group-hover:bg-dark-300 group-hover:border-light-300 text-text-light-500',
    },
    disabled: {
      dark: 'border border-dark-300 text-text-dark-100 placeholder-text-dark-100',
      light: 'border border-light-300 text-text-light-100 text-text-light-500',
    },
    error: {
      dark: 'border border-dark-300 text-text-dark-500',
      light: 'border border-light-300 text-text-light-500',
    },
  },
  hasValue: {
    default: {
      dark: 'border bg-dark-300 border-dark-300 text-text-dark-500',
      light: 'border bg-light-300 border-light-300 text-text-light-500',
    },
    active: {
      dark: 'border bg-dark-300 border-dark-300 text-text-dark-500',
      light: 'border bg-light-300 border-light-300 text-text-light-500',
    },
    hover: {
      dark: 'group-hover:border-dark-400 text-text-dark-500',
      light: 'group-hover:border-light-400 text-text-light-500',
    },
    disabled: {
      dark: 'border border-dark-400 text-text-dark-100 placeholder-text-dark-100',
      light: 'border border-light-400 text-text-light-100 placeholder-text-light-100',
    },
    error: {
      dark: 'border bg-dark-300 border-dark-300 text-text-dark-500',
      light: 'border bg-light-300 border-light-300 text-text-light-500',
    },
  },
};
