<script lang="ts" setup>
import { computed, ref } from 'vue';
import type { Placement, Strategy } from '@floating-ui/core';
import { offset, shift, flip } from '@floating-ui/core';
import { arrow, arrowPlacement, useFloating, useKeyboardShortcut, useOutsideClick } from '@gem/common';

type Props = {
  ignoreOutsideClass?: string[];
  closeable?: boolean;
  wrapperClass?: string;
  wrapContentClass?: string;
  buttonClass?: string;
  arrowClass?: string;
  overlayContainer?: string;
  overlay?: boolean;
  overlayClass?: string;
  strategy?: Strategy;
  disabled?: boolean;
  hasArrow?: boolean;
  placement?: Placement;
  offsetTop?: number;
  placementOffset?: number;
  stopPropagation?: boolean;
  preventDefault?: boolean;
  cls?: string;
  paddingRight?: number;
  noShadowBox?: boolean;
  isHiddenDom?: boolean;
  isOpenModal?: boolean;
  iframeSelector?: string;
  noPadding?: boolean;
  trigger?: 'click' | 'hover';
  enableFlip?: boolean;
  detectSideBar?: boolean;
  zIndex?: number;
  customToggle?: boolean;
  forceOpen?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  overlayContainer: 'body',
  overlayClass: 'bg-dark-500/80',
  strategy: 'fixed',
  hasArrow: false,
  autoPlacement: true,
  paddingRight: 0,
  noShadowBox: false,
  isHiddenDom: false,
  isOpenModal: false,
  trigger: 'click',
  enableFlip: true,
  offsetTop: 0,
  forceOpen: undefined,
});

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'open'): void;
  (e: 'clickOutSide'): void;
  (e: 'click'): void;
}>();

const open = ref(false);
const arrowEl = ref<HTMLElement>();
const timeout = ref<any>(null);

const displayPopover = computed(() => (props.forceOpen === undefined ? open.value : props.forceOpen));
const transitionName = computed(() => {
  if (props.placement?.includes('right')) {
    // return 'popover-right';
    return undefined;
  }
  return 'popover';
});

function close() {
  open.value = false;
  emit('close');
}
function onOpen() {
  open.value = true;
  emit('open');
}
defineExpose({ close, onOpen, updatePosition, open });

function onToggle() {
  if (props.customToggle) return;
  if (!open.value) {
    open.value = true;
    emit('open');
  } else {
    close();
    emit('close');
  }
}

function updatePosition(parentRef?: any) {
  if (parentRef) {
    setParentRef(parentRef);
  }
}

const { x, y, floating, reference, strategy, middlewareData, setParentRef } = useFloating({
  strategy: props.strategy,
  placement: props.placement,
  middleware: [
    props.enableFlip ? flip() : undefined,
    shift(),
    offset(props.placementOffset ?? 8),
    arrow({
      element: arrowEl,
    }),
    arrowPlacement(),
  ],
});

function onClickOpen(e: MouseEvent) {
  emit('click');
  if (props.trigger !== 'click') return;
  if (props.stopPropagation) e.stopPropagation();
  if (props.preventDefault) e.preventDefault();
  onToggle();
}

const handleClickOutSide = (isClickSidebar?: boolean) => {
  emit('clickOutSide');
  if ((props.closeable || isClickSidebar) && open.value) {
    close();
    emit('close');
  }
};

useOutsideClick(floating, handleClickOutSide, {
  containSelectors: props.ignoreOutsideClass ? props.ignoreOutsideClass.map((el) => `.${el}`) : [],
  detectIframe: true,
  detectSideBar: props.detectSideBar,
  iframeSelector: props.iframeSelector,
});

useKeyboardShortcut({
  callback(event: KeyboardEvent) {
    if (event.key === 'Escape' && open) {
      handleClickOutSide();
    }
  },
});

function onMouseOver() {
  if (timeout.value) clearTimeout(timeout.value);
  if (props.trigger === 'click') return;
  if (!open.value) {
    open.value = true;
    emit('open');
  }
}
function onMouseOut() {
  if (timeout.value) clearTimeout(timeout.value);
  if (props.trigger === 'click') return;
  if (open.value) {
    timeout.value = setTimeout(() => {
      open.value = false;
      emit('close');
    }, 200);
  }
}
</script>

<template>
  <div ref="reference" :class="buttonClass" class="popover-control relative inline-flex" :data-is-open="open">
    <div :class="wrapContentClass" @click="onClickOpen" @mouseover="onMouseOver" @mouseleave="onMouseOut">
      <slot v-bind="{ open: displayPopover, close }"></slot>
    </div>
    <Teleport :to="overlayContainer">
      <Transition v-if="overlay" name="overlay">
        <div
          v-if="displayPopover"
          :style="{ left: x && detectSideBar ? `${x - paddingRight}px` : `0px` }"
          :class="overlayClass"
          class="absolute inset-0 z-30"
          @click="() => close()"></div>
      </Transition>
      <Transition :name="transitionName">
        <div
          v-if="isHiddenDom ? displayPopover : true"
          v-show="!isHiddenDom ? displayPopover : true"
          ref="floating"
          :style="{
            position: strategy,
            top: y ? `${y + offsetTop}px` : '',
            left: x ? `${x - paddingRight}px` : `0px`,
          }"
          :class="[
            'popover-control-modal',
            { 'shadow-4dp': !noShadowBox },
            wrapperClass,
            isOpenModal && 'hidden',
            `z-[${zIndex ? zIndex : 999}]`,
          ]"
          @mouseover="onMouseOver"
          @mouseleave="onMouseOut">
          <div class="rounded-12 relative text-white" :class="[cls, { 'bg-dark-400': hasArrow, 'p-8': !noPadding }]">
            <div
              v-if="hasArrow"
              ref="arrowEl"
              class="border-r-dark-400 absolute border-y-4 border-r-4 border-l-0 border-solid border-y-transparent"
              :style="{
                top: middlewareData.arrow?.y != null ? `${middlewareData.arrow.y}px` : '',
                left: middlewareData.arrow?.x != null ? `${middlewareData.arrow.x}px` : '',
              }"
              :class="[
                arrowClass,
                {
                  '-right-8 rotate-180': middlewareData.arrowPlacement?.placement?.startsWith('left'),
                  '-left-8': middlewareData.arrowPlacement?.placement?.startsWith('right'),
                  'top-[-6px] rotate-90': middlewareData.arrowPlacement?.placement?.startsWith('bottom'),
                  'bottom-[-6px] -rotate-90': middlewareData.arrowPlacement?.placement?.startsWith('top'),
                },
              ]"></div>
            <div>
              <slot name="content" v-bind="{ open: displayPopover, close }"></slot>
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<style scoped>
.popover-enter-active,
.popover-leave-active {
  transition: opacity 0.2s ease;
  opacity: 1;
  /* top: 100%; */
}

.popover-enter-from,
.popover-leave-to {
  opacity: 0;
}

.popover-right-enter-active {
  animation-name: fadeInLeft;
  animation-duration: 0.3s;
}

.popover-right-enter-from,
.popover-right-leave-to {
  opacity: 0;
}

.overlay-enter-active,
.overlay-leave-active {
  transition: opacity 0.3s ease;
}

.overlay-enter-from,
.overlay-leave-to {
  opacity: 0;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
