<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.5H8C5.51472 5.5 3.5 7.51472 3.5 10C3.5 12.4853 5.51472 14.5 8 14.5H12C14.4853 14.5 16.5 12.4853 16.5 10C16.5 7.51472 14.4853 5.5 12 5.5ZM8 4C4.68629 4 2 6.68629 2 10C2 13.3137 4.68629 16 8 16H12C15.3137 16 18 13.3137 18 10C18 6.68629 15.3137 4 12 4H8Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'CornerPill'
};
</script>
