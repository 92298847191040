<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 5.5H3.5V14.5H16.5V5.5ZM2 4V16H18V4H2Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'CornerSquare'
};
</script>
