<script lang="ts" setup>
import type { GDropdownMenuItemProps, GDropdownMenuProps } from '../types/dropdownMenu';
import { computed, ref, watch } from 'vue';
import GDropdownMenuItem from './GDropdownMenuItem.vue';
import { DROPDOWN_MENU_STYLE_BY_SIZE } from '../const/dropdownMenu';
import { cn } from '@gem/common';
import { GButton } from '@gem/uikit-v2';

const emit = defineEmits<(e: 'onSelect', value: GDropdownMenuItemProps['value'][]) => void>();
const props = withDefaults(defineProps<GDropdownMenuProps>(), {
  lightMode: false,
  size: 'medium',
  enableSearch: false,
  enableAction: false,
  width: '100%',
  isMultiSelect: false,
  showCheckedIcon: false,
});
const stylesBySize = computed((): string =>
  props.size ? DROPDOWN_MENU_STYLE_BY_SIZE[props.size] : DROPDOWN_MENU_STYLE_BY_SIZE.medium,
);

const selectedItems = ref(props.selected || []);

const removeItemFormSelectedItem = (item: string) => {
  if (props.minSelected && props.minSelected === selectedItems.value.length) return;
  const itemIndex = selectedItems.value.findIndex((i: string) => item === i);
  if (itemIndex > -1) {
    selectedItems.value.splice(itemIndex, 1);
  }
};

const addSelectedItem = (item: string) => {
  if (props.maxSelected && props.maxSelected === selectedItems.value.length) return;
  selectedItems.value.push(item);
};

const handleClick = (e: Event, value: GDropdownMenuItemProps['value']) => {
  if (props.isMultiSelect) {
    !selectedItems.value.includes(value) ? addSelectedItem(value) : removeItemFormSelectedItem(value);
  } else {
    selectedItems.value = [value];
  }
  emit('onSelect', selectedItems.value);
};

watch(
  () => props.selected,
  (value) => {
    selectedItems.value = value || [];
  },
);
</script>

<template>
  <div
    class="rounded-12 flex flex-col"
    :class="cn([stylesBySize, lightMode ? 'bg-light-100' : 'bg-dark-400'])"
    :style="{
      width: width,
    }">
    <div :class="items?.length > 6 ? 'h-[187px]' : 'h-auto'">
      <perfect-scrollbar ref="scrollBody" :class="items?.length > 6 ? 'pr-8' : ''" :options="{ suppressScrollX: true }">
        <GDropdownMenuItem
          v-for="item in items"
          :key="item.value"
          v-bind="item"
          :active="selectedItems.includes(item.value) || selectedItems.includes(item.displayValue)"
          :size="size"
          :light-mode="lightMode"
          :show-checked-icon="isMultiSelect || showCheckedIcon"
          @click="handleClick"
      /></perfect-scrollbar>
    </div>
    <div v-if="moreAction" class="border-light-500/[0.15] flex justify-center border-t pt-8 pb-4">
      <GButton type="link" size="small" button-width="full" @click="moreAction.action">{{ moreAction.label }}</GButton>
    </div>
  </div>
</template>
