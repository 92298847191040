<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15 5.5H5C4.17157 5.5 3.5 6.17157 3.5 7V13C3.5 13.8284 4.17157 14.5 5 14.5H15C15.8284 14.5 16.5 13.8284 16.5 13V7C16.5 6.17157 15.8284 5.5 15 5.5ZM5 4C3.34315 4 2 5.34315 2 7V13C2 14.6569 3.34315 16 5 16H15C16.6569 16 18 14.6569 18 13V7C18 5.34315 16.6569 4 15 4H5Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'CornerRounded'
};
</script>
